

























  import { defineComponent } from '@vue/composition-api'
  import TopBar from '@/components/organisms/o-top-bar.vue'
  import { RawLocation } from 'vue-router'

declare interface TabItem {
  tab: string
  route: RawLocation | null
  disabled?: boolean
}

  export default defineComponent({
    name: 'BookingTabs',
    components: {
      TopBar,
    },
    data () {
      return {
        title: this.$t('booking.bookings'),
        tab: 0,
        pages: [] as Array<TabItem>,
      }
    },
    async created () {
      await this.init()
    },
    watch: {
      $route () {
        this.init()
      },
    },
    methods: {
      async init () {
        this.pages = [
          {
            tab: this.$t('booking.status.pending') as string,
            route: { name: 'Booking List' },
          },
          {
            tab: this.$t('booking.status.canceled') as string,
            route: { name: 'Canceled Booking List' },
          },
        ]
      },
    },
  })
